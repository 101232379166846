import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/tsuto/webapp/pcrecommend/src/components/layout.js";
import Image from "components/image";
import Socials from "components/socials";
import SmallBox from "components/smallBox";
import AccentBox from "components/accentBox";
import { Link, graphql } from "gatsby";
import SaleLoadable from "components/saleLoadable";
import NoteMakerRanking from 'components/noteMakerRankingLoadable';
export const query = graphql`
  {
    allMdx(filter: {slug: {eq: "notepc-maker"}}) {
      nodes {
        headings {
          depth
          value
        }
      }
    },
    cover: file(relativePath: { eq: "cover/rank/notepc-maker.png"}) { ...eyecatchImg },
    apple: file(relativePath: { eq: "pcmaker/apple.png"}) { ...normalImg },
    lenovo: file(relativePath: { eq: "pcmaker/lenovo.png"}) { ...normalImg },
    dell: file(relativePath: { eq: "pcmaker/dell.png"}) { ...normalImg },
    mouse: file(relativePath: { eq: "pcmaker/mouse.png"}) { ...normalImg },
    koubou: file(relativePath: { eq: "pcmaker/koubou.png"}) { ...normalImg },
    frontier: file(relativePath: { eq: "pcmaker/frontier.png"}) { ...normalImg },
    fujitsu: file(relativePath: { eq: "pcmaker/fujitsu.png"}) { ...normalImg },
    hp: file(relativePath: { eq: "pcmaker/hp.png"}) { ...normalImg },
    nec: file(relativePath: { eq: "pcmaker/nec.png"}) { ...normalImg },
    dynabook: file(relativePath: { eq: "pcmaker/dynabook.png"}) { ...normalImg },
    microsoft: file(relativePath: { eq: "pcmaker/microsoft.png"}) { ...normalImg },
    asus: file(relativePath: { eq: "pcmaker/asus.png"}) { ...normalImg },
    tsukumo: file(relativePath: { eq: "pcmaker/tsukumo.png"}) { ...normalImg },
    vaio: file(relativePath: { eq: "pcmaker/vaio.png"}) { ...normalImg },
    lg: file(relativePath: { eq: "pcmaker/lg.png"}) { ...normalImg },
  }
`;
export const _frontmatter = {};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`ノートパソコンおすすめメーカーランキング`}</h1>
    <Socials {...props} mdxType="Socials" />
    <Image {...props} name="cover" alt="ノートパソコンのおすすめメーカーランキング" mdxType="Image" />
    <p>{`ノートパソコンにはCPU、メモリ、ストレージといったパソコンの基本的な機能を備えていることの他に、バッテリーの持続時間、デザイン性、軽さ、タッチパネル対応など様々な要素がある。`}</p>
    <p>{`このためモバイル性ならNECや富士通、東芝、デザイン性ならマイクロソフト、コスパならばLenovoやDellが優れているなどメーカーによって得意とする領域が異なり、単におすすめランキングを提示されても困惑することだろう。`}</p>
    <p>{`そこで本ページではノートパソコンの`}<strong parentName="p">{`大まかな種類(`}<em parentName="strong">{`モバイル`}</em>{`/`}<em parentName="strong">{`スタンダード`}</em>{`/`}<em parentName="strong">{`ゲーミング`}</em>{`)`}</strong>{`と`}<strong parentName="p">{`こだわり(`}<em parentName="strong">{`コスパ`}</em>{`、`}<em parentName="strong">{`デザイン`}</em>{`、`}<em parentName="strong">{`サポート`}</em>{`等、複数選択可)`}</strong>{`を選んでもらった上でランキングを並び変えて付けていくことにした。`}</p>
    <p>{`デフォルトでは当サイトでの人気順に並べてある。ただ、人気順とはいえデスクトップパソコンも含めたトータルでの人気となるので、参考程度にしつつ、選択肢を選んでノートパソコンのランキングを見ていくことをおすすめする。`}</p>
    <p>{`また、自分に適したノートパソコンのスペックが分からないという場合は当サイト`}<Link to="/" mdxType="Link">{`パソコン選び方ガイドのパソコン診断`}</Link>{`や`}<Link to="/cpu/" mdxType="Link">{`CPU性能比較表`}</Link>{`を参考にして欲しい。`}</p>
    <SaleLoadable title="現在のパソコンセール・お買い得情報" mdxType="SaleLoadable" /><br />
    <NoteMakerRanking {...props} mdxType="NoteMakerRanking" />


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      